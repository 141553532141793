import React from 'react';
import './Aboutstyle.css';

const About = () => {
	return (
		<div className="Aboutstyle">
			<h2>Welcome to about us page.</h2>
		</div>
	)
}

export default About;
