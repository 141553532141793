import React from 'react';
import Form from './Form';
import './Contactstyle.css';


const Contact = () => {
	return (
		<div className="Contactstyle">
			<h2>Welcome to contact us page.</h2>
			<br/>
			<Form/>
		</div>
	)
}

export default Contact;
