import React,{Component} from 'react';
import './Formstyle.css';

class Form extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			fullname: "Ganesh",
			email: "ganeshfizzy@gmail.com",
			mobile: "9583708587",
			msg: "Subscribe me"
		}
	}
	/*handler =(event) => {
		this.setState({fullname: event.target.value})
		console.log(event.target.value);
	}
	handleremail =(event) => {
		this.setState({email: event.target.value})
		console.log(event.target.value);
	}
	handlerphone =(event) => {
		this.setState({mobile: event.target.value})
		console.log(event.target.value);
	}
	handlermsg =(event) => {
		this.setState({msg: event.target.value})
		console.log(event.target.value);
	}*/
	handlerall =(event) => {
	
		this.setState({ [event.target.name]: event.target.value })
		//console.log(JSON.stringify(this.state));
	}
	
	handlesubmit =(event) => {
	
		alert(JSON.stringify(this.state));
		event.preventDefault();
	}
	
	render(){
		return(
			<div className="form_css">
				<form onSubmit={this.handlesubmit}>
					<label>Full Name</label><br/>
					<input type="text" name="fullname" value={this.state.fullname} onChange={this.handlerall} /> <br/>
					<label>Email</label><br/>
					<input type="email" name="email" value={this.state.email} onChange={this.handlerall} /> <br/>
					<label>Mobile</label><br/>
					<input type="number" name="mobile" value={this.state.mobile} onChange={this.handlerall} /> <br/>
					<label>Message</label><br/>
					<textarea name="msg" value={this.state.msg} onChange={this.handlerall} /> <br/>
					<input type="submit" name="submit" value="Send" />
				</form>
			</div>
		)
	}
}

export default Form;
