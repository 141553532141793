import React from 'react';
import logo from './logo.svg';
import './App.css';
import Menu from './Menu';
import About from './About';
import Contact from './Contact';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

function App() {
  return (
  <BrowserRouter>
    <div>
	  <Menu />
		<Switch>
			  <Route path='/' exact component={Home} />
			  <Route path='/about' component={About} />
			  <Route path='/contact' component={Contact} />
		</Switch>
    </div>
	</BrowserRouter>
  );
}

const Home = () => {
	return (
		<div className="Contactstyle">
			<h2>Welcome to home page.</h2>
			<p>Evaluate Two numbers are {evaluate(20,10)} </p>
			<button onClick ={clickme}>Click here</button>
		</div>
	)
}

function evaluate(a,b){
	if(a > b)
	{
		return <span className='subtract'>{a-b}</span>
	}
		return <span className='add'>{a+b}</span>
}

const clickme = () => alert('Hello');


export default App;
